.item {
    position: relative;
  }
  
  .item::after {
    content: '';
    position: absolute;
    /* top: 50; */
    bottom: -40%;
    left: 40%;
    border-radius: 50%;
    width: 15px;
    height: 15px; /* Adjust thickness */
    color: #ffaa17;
    background: linear-gradient(146deg, #DA9127 0%, #E5B144 4%, #EFCD5D 8%, #F6E16F 12%, #FAED79 16%, #FBF17D 20%, #E7BD4A 28%, #D99A28 34%, #CB8F29 36%, #B77F29 40%, #AB762A 43%, #A7732A 47%, #AC782E 51%, #B98738 56%, #CEA04A 61%, #ECC362 67%, #F6CE6A 68%, #F3C963 72%, #EBBA50 78%, #DDA232 85%, #D99A28 87%, #F6CE6A 100%);
    display: none;
  }
  
  .item.active::after {
    display: block;
  }
  