/* BannerinputGetModal.css */

/* Dialog styles */
.custom-dialog {
    overflow-x: auto;
}

.custom-dialog .MuiDialog-paper {
    overflow-y: auto;
    width: 100%;


}

/* Dialog Title Styles */
.custom-dialog-title {
    display: flex;
    align-items: center;
    padding: 16px;
    color: white;
}

.custom-title-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(83, 193, 205, 0.2);
    margin-right: 8px;
}

.custom-title-text {
    /* font-weight: bold; */
    font-size: 16px;
}

/* Buttons */
.custom-button {
    width: 100%;
    margin-top: 16px;
    font-weight: 700;
    background: linear-gradient(90deg, rgba(80, 197, 209, 1) 13%, rgba(76, 94, 96, 1) 57%);
    color: white;
}

.custom-clear-button {
    background: rgb(249, 249, 249);
    color: black;
}

.custom-clear-button:hover {
    background-color: rgb(249, 249, 249);
    color: black;
}

.custom-ok-button {
    background-color: var(--button-bgcolor);
    color: var(--button-color);
}

.custom-closeicon {
    position: absolute;
    top: 8;
    right: 8;
    color: black
}
.close-iconbox{
    position: relative;
}
.dialog-heading{
    background-color: #C38F51;
}