.custominput-field12 {

  height: 35px;

  width: 100%;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  padding-left: 15px;
  /* border-radius: 12px; */
  color: #7A7A7A;
    border-radius: 7px;

  font-size: 14px;
  outline: none;
  /* Remove outline when focused */
  border: 1px solid #ced4da;
  /* Add border style to replace outline */
}

.custominput-field12:focus {
  border-color: black;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0) !important;
  /* Set border color to 1px when focused */
}