.icon {
    height: 65px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: rgb(64, 112, 244); */
    background: #C38F51;
    border-radius: 50%;
}

.icon svg {
    color: rgb(255, 255, 255);
    font-size: 2.5rem;
}

.container {
    row-gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
    background: rgb(255, 255, 255);
    padding: 30px 65px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}